<template>
  <div>
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <form @submit.prevent="send">

            <div class="row justify-content-md-center mt-3">
              <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="widget-holder" :class="form.mode === 'join' ? 'rounded' : 'rounded-top'">
                  <div class="widget-bg">
                    <div class="widget-body">

                      <h2 class="mb-5 text-center">Sign Up</h2>

                      <div class="form-group">
                        <label>Full Name</label>
                        <input type="text" ref="name" v-model="form.name" class="form-control" maxlength="254" required />
                      </div>
                      <div class="form-group">
                        <label>Email Address</label>
                        <input type="email" v-model="form.email" class="form-control" maxlength="254" required :disabled="disableEmail === true" />
                      </div>
                      <div class="form-group">
                        <label>Username  <i class="fal fa-info-circle fa-lg text-primary" v-b-tooltip.hover title="Your username will appear on your issues and comments."></i></label>
                        <input type="text" v-model="form.username" class="form-control" maxlength="39" required />
                      </div>
                      <div class="form-group">
                        <label>Password</label>
                        <input type="password" v-model="form.password" class="form-control" maxlength="254" required />
                      </div>

                      <div class="form-group" v-if="!disableEmail && !$route.query.mode">
                        <div class="radiobox radio-primary">
                          <label><input type="radio" v-model="form.mode" value="join"><span class="label-text">Join my team on {{ $config.pretty_name }}</span></label>
                        </div>
                        <div class="radiobox radio-primary">
                          <label><input type="radio" v-model="form.mode" value="company"><span class="label-text">Sign up a new company</span></label>
                        </div>
                      </div>
                      <div v-else class="pt-3"></div>

                      <div v-if="form.mode === 'join'">
                        <div class="form-group mx-sm-3 px-sm-5 mb-2">
                          <button type="submit" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple" :disabled="waiting"><i v-if="waiting" class="fal fa-spinner fa-spin mr-2" style="margin-left: -22px;"></i>Create Account</button>
                        </div>

                        <div class="text-center small pb-4">
                          By clicking "Create Account" you agree to the<br>
                          {{ $config.pretty_name }}
                          <router-link v-if="$config.termslink.charAt(0) === '/'" class="link" :to="$config.termslink">Terms of Service</router-link><a v-else class="link" :href="$config.termslink">Terms of Service</a>
                          and
                          <router-link v-if="$config.privacylink.charAt(0) === '/'" class="link" :to="$config.privacylink">Privacy Policy</router-link><a v-else class="link" :href="$config.privacylink">Privacy Policy</a>.
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div v-if="form.mode === 'company'" class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body">

                      <h3 class="mb-4 text-center">Business Profile</h3>

                      <div v-if="$config.name === 'agiler'" class="form-group">
                        <label>Business Name</label>
                        <input type="text" v-model="form.company" class="form-control" maxlength="254" :required="form.mode === 'company'" />
                      </div>

                      <div class="form-group">
                        <label class="col-form-label">Website</label>
                        <div class="input-group">
                          <div class="d-none d-sm-flex input-group-prepend"><span class="input-group-text">https://</span></div>
                          <input type="text" v-model="form.website" class="form-control" maxlength="254" :required="form.mode === 'company'" />
                        </div>
                      </div>

                      <div v-if="$config.name !== 'agiler'" class="form-row mb-3">
                        <div class="col-12">
                          <div class="form-group mt-2 mb-3 pb-1">
                            <label>Business Name</label>
                            <input type="text" v-model="form.company" class="form-control" maxlength="254" :required="form.mode === 'company'" />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label class="col-form-label">Business Address</label>
                            <input type="text" class="form-control" v-model="form.address1" maxlength="100" placeholder="Address">
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <input type="text" class="form-control" v-model="form.address2" maxlength="100">
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="form-group">
                            <input type="text" class="form-control" v-model="form.city" maxlength="50" placeholder="City">
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <input type="text" class="form-control" v-model="form.state" maxlength="50" :placeholder="form.country === 'CA' ? 'Province' : 'State'">
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <input type="text" class="form-control" v-model="form.zip" maxlength="50" :placeholder="form.country === 'CA' ? 'Postal Code' : 'Zip'">
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <select v-model="form.country" class="form-control custom-select">
                              <option value="US">United States</option>
                              <option value="CA">Canada</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mt-3">
                            <label class="col-form-label">Phone number</label>
                            <input type="text" class="form-control" v-model="form.phone" maxlength="50">
                          </div>
                        </div>
                      </div>

                      <div class="pt-2"></div>

                    </div>
                  </div>
                </div>
                <div v-if="form.mode === 'company' && $config.name === 'agiler'" class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body">

                      <h3 class="mb-4 text-center">Service Packages</h3>

                      <div class="form-group">
                        <div class="radiobox radio-primary">
                          <label><input type="radio" v-model="form.plan" value="essentials"><span class="label-text">
                            <span class="h6"><span class="fw-500">Essentials</span> &bull; $3900/mo</span>
                          </span></label>
                        </div>
                        <ul v-if="form.plan === 'essentials'" class="ml-2 mb-2">
                          <li class="mb-2">24/7/365 Store Support</li>
                          <li class="mb-2">Analytics & Marketing Support</li>
                          <li class="mb-2">Search Engine Optimization (SEO)</li>
                          <li class="mb-2">Performance Tuning</li>
                          <li class="mb-2">Store Updates & Bug Fixes</li>
                          <li class="mb-2">Plugin Setup & Maintenance</li>
                          <li class="mb-2">Backups & Disaster Recovery</li>
                        </ul>
                        <div class="radiobox radio-primary">
                          <label><input type="radio" v-model="form.plan" value="optimization"><span class="label-text">
                            <span class="d-none d-lg-inline h6"><span class="fw-500">Essentials + Optimization</span> &bull; $5900/mo</span>
                            <span class="d-lg-none h6"><span class="fw-500">Optimization</span> &bull; $5900/mo</span>
                          </span></label>
                        </div>
                        <ul v-if="form.plan === 'optimization'" class="ml-2 mb-2">
                          <li class="mb-2">Analytics Data Analysis</li>
                          <li class="mb-2">Test Design</li>
                          <li class="mb-2">Test Implementation &amp; Monitoring</li>
                          <li class="mb-2">Rapid Testing</li>
                          <li class="mb-2">Customer Life Cycle Analysis</li>
                          <li class="mb-2">Email &amp; Mailing List Optimization</li>
                          <li class="mb-2 font-weight-bold">Essentials plan included</li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
                <div v-if="form.mode === 'company'" class="widget-holder rounded-bottom">
                  <div class="widget-bg">
                    <div class="widget-body">

                      <div class="form-group mx-sm-3 px-sm-5 mb-2 mt-4">
                        <button type="submit" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple" :disabled="waiting"><i v-if="waiting" class="fal fa-spinner fa-spin mr-2" style="margin-left: -22px;"></i>Create Account</button>
                      </div>

                      <div class="text-center small pb-3">
                        By clicking "Create Account" you agree to the<br>
                        {{ $config.pretty_name }}
                        <router-link v-if="$config.termslink.charAt(0) === '/'" class="link" :to="$config.termslink">Terms of Service</router-link><a v-else class="link" :href="$config.termslink">Terms of Service</a>
                        and
                        <router-link v-if="$config.privacylink.charAt(0) === '/'" class="link" :to="$config.privacylink">Privacy Policy</router-link><a v-else class="link" :href="$config.privacylink">Privacy Policy</a>.
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { TooltipPlugin } from 'bootstrap-vue';
import Header from '@/components/Header.vue';
import toast from '@/modules/toast';

Vue.use(TooltipPlugin);

export default {
  data() {
    return {
      disableEmail: false,
      form: {
        mode: 'join',
        plan: 'essentials',
        country: 'US',
      },
      waiting: false,
    };
  },
  methods: {
    async send() {
      try {
        this.waiting = true;
        await this.$api.post('/users', this.form);
        // save account
        await this.$auth.logIn(this.form.email, this.form.password);
        // attempt email verification
        if (this.$route.query.code) {
          try {
            await this.$api.post('/profile/verify', { email: this.form.email, code: this.$route.query.code });
            await this.$auth.loadProfile();
          } catch {
            // do nothing
          }
        }
        this.waiting = false;
        if (this.$auth.profile.update_billing) {
          this.$router.push('/profile/billing/update');
        } else if (this.$auth.profile.verify_email) {
          this.$router.push('/profile/verify');
        } else {
          this.$router.push('/dashboard');
        }
      } catch (e) {
        toast.danger(this, e.response.data.message);
        this.waiting = false;
      }
    },
  },
  async mounted() {
    if (this.$route.query.mode === 'company') {
      this.form.mode = this.$route.query.mode;
    }
    if (this.$route.query.plan) {
      this.form.plan = this.$route.query.plan;
    }
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email;
      this.disableEmail = true;
    }
    this.$nextTick(() => { this.$refs.name.focus(); });
  },
  components: {
    Header,
  },
};
</script>
